<template>
  <div class="vertical-layout" :class="[layoutClasses]" :data-col="isNavMenuHidden ? '1-column' : null">
    <nav class="header-navbar navbar navbar-expand-lg align-items-center navbar-shadow" :class="[navbarTypeClass]" :toggleVerticalMenuActive="toggleVerticalMenuActive">
      <div class="navbar-container d-flex align-items-center content">
        <div class="bookmark-wrapper d-flex align-items-center">
          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item" @click="toggleVerticalMenuActive">
              <a class="nav-link menu-toggle" href="#"><i class="ficon"></i></a>
            </li>
          </ul>
        </div>
        <h4 class="ml-.5 d-md-none">{{ $route.meta.pageTitle }}</h4>
        <ul class="nav navbar-nav align-items-center ml-auto">
          <li class="nav-item dropdown dropdown-user">
            <b-dropdown toggle-class="nav_profileBox" data-bs-toggle="dropdown">
              <template #button-content>
                <img src="@/assets/images/custom/icon_profile.png" alt="" />
                <span>{{userName}}</span>님
              </template>
              <b-dropdown-item class="dropdown-item"  @click="goLogout"><i class="nav_log"></i>Log out</b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Vertical Nav Menu -->
    <vertical-nav-menu v-if="!isNavMenuHidden" :is-vertical-menu-active="isVerticalMenuActive" :toggle-vertical-menu-active="toggleVerticalMenuActive">
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- CONTENT TYPE: Left -->
    <transition :name="routerTransition" mode="out-in">
      <component :is="layoutContentRenderer" :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    // AppBreadcrumb,
    VerticalNavMenu,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault
  },
  mixins: [mixinVerticalLayout],
  data() {
    return {
      userInfoFlag: false,
      userName: ''
    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const { routerTransition, navbarType, footerType, isNavMenuHidden } = useAppConfig()

    const { isVerticalMenuActive, toggleVerticalMenuActive, isVerticalMenuCollapsed, layoutClasses, overlayClasses, resizeHandler, navbarTypeClass, footerTypeClass } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      isNavMenuHidden
    }
  },
  methods: {
    toggleUserInfo() {
      this.userInfoFlag = !this.userInfoFlag
    },
    goLogout() {
      this.logout()
    },
    ...mapActions('common', ['logout'])
  },
  mounted() {
    this.userName = sessionStorage.getItem('userName')
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/themes/bordered-layout.scss';
</style>
