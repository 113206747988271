<template>
  <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header row d-none d-md-block">
    <!-- Content Left -->
    <div class="content-header-left col-md-9 col-12 mb-1">
      <div class="row breadcrumbs-top">
        <div class="col-12">
          <div class="pl-1 pl-md-0">
            <h3 class="content-header-title">
              {{ $route.meta.pageTitle }}
            </h3>
          </div>
          
          <div class="breadcrumb-wrapper" v-if="$route.meta.breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" v-for="item in $route.meta.breadcrumb" :key="item.text" :active="item.active" :to="item.to">
                {{ item.text }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton
  }
}
</script>
