<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[{ expanded: expandedFlag }, skin === 'semi-dark' ? 'menu-dark' : 'menu-light']" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item me-auto">
            <a class="navbar-brand" @click.prevent="moveToLink('main')">
              <span class="brand-logo">
                <img v-if="expandedFlag" src="@/assets/images/custom/logo_samsung.png" alt="" />
                <img class="small" v-else src="@/assets/images/custom/logo_samsung2.png" alt="" />
              </span>
            </a>
          </li>
          <li class="nav-item nav-toggle" @click="toggleOrActive">
            <a class="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse"></a>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->

    <!-- main menu content-->
    <div class="main-menu-content">
      <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <li class="nav-item nav_01" :class="{ 'active open': isOpen('main') }">
          <a class="d-flex align-items-center link" v-b-toggle.dashboard @click.prevent="moveToLink('main')">
            <span class="menu-title text-truncate">메인</span>
          </a>
        </li>
        <li class="nav-item nav_02" :class="{ 'active open': isOpen('bidPrices') }">
          <a class="d-flex align-items-center link" v-b-toggle.dashboard @click.prevent="moveToLink('bidPrices')">
            <span class="menu-title text-truncate">입찰가 관리</span>
          </a>
        </li>
        <li class="nav-item nav_03" :class="{ 'active open': isOpen('bidLogs') }">
          <a class="d-flex align-items-center link" v-b-toggle.dashboard @click.prevent="moveToLink('bidLogs')">
            <span class="menu-title text-truncate">입찰 로그</span>
          </a>
        </li>
      </ul>
      <p class="copy_txt">Copyright 2023 incross<span>All Rights Reserved</span></p>
    </div>
    <!-- /main menu content-->
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/vertical'
import { provide } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      keepOpen: false,
      noImgFlag: false,
      openSub: '',
      reportFlag: false,
      mediaFlag: false,
      keywordFlag: false
    }
  },
  setup(props) {
    const { isMouseHovered, isVerticalMenuCollapsed, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }

    return {
      // navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      // Skin
      skin
    }
  },
  mounted() {
    this.openSub = location.pathname.split('/')[1]
    if (this.$refs.hasOwnProperty(this.openSub)) {
      this.$refs[this.openSub].toggle()
    }
  },
  computed: {
    expandedFlag() {
      return !this.isVerticalMenuCollapsed || (this.isVerticalMenuCollapsed && this.isMouseHovered)
    }
  },
  methods: {
    toggleOrActive() {
      if (window.innerWidth >= 1200) {
        this.toggleCollapsed()
      } else {
        this.toggleVerticalMenuActive()
      }
    },
    isOpen(link) {
      if (this.openSub === link) {
        return true
      }
      return false
    },
    isOpenSub(link) {
      if (this.$route.path === link) {
        return true
      } else {
        return false
      }
    },
    clickOpenSub(link) {
      this.openSub = link
    },
    moveToLink(link) {
      this.toggleVerticalMenuActive()
      if (link !== 'bidPrices') this.openSub = link
      this.$router.push({
        path: '/' + link
      })
    }
  },
  watch: {
    '$route.name': {
      handler() {
        this.openSub = this.$route.path.startsWith('/') ? this.$route.path.substr(1) : this.$route.path
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
